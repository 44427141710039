$(document).ready(function() {

    $(document).on('submit', 'form[data-remote]', submitLaravelRequest);

    $(document).on('click', 'a#toggle-sidenav', function(e) {
        e.preventDefault();

        $('body').toggleClass('shortsidenav');
    })

    if ( $('#tag_list.profile-tags').length ) {
        $('#tag_list').select2({
            tags: true,
            // theme: "bootstrap",
            createTag: function (tag) {

                // check if the option is already there
                var found = false;
                $("#tag_list option").each(function() {
                    if ($.trim(tag.term).toUpperCase() == $.trim($(this).text()).toUpperCase()) {
                        found = true;
                    }
                });

                // show the suggestion only if a match was not found
                if (!found) {
                    return {
                        id: tag.term,
                        text: tag.term + " (нов)",
                        newTag: true
                    };
                }
            }
        })

        // $('.select2-container').addClass('input-sm')

        $('#tag_list').on('select2:select', function(ev) {
            if ( ev.params.data.newTag ) {
                var
                    $form = $(this).parents('form'),
                    $select2Element = $(this);

                $.ajax({
                    type     : "POST",
                    url      : '/admin/tags',
                    data     : { _token: $('input[name=_token]', $form).val(), name: ev.params.data.id },
                    dataType : 'json',
                    // processData: false,
                    success  : function( data, textStatus, jqXhr ) {
                        // $('option[value=' + data.name + '][data-select2-tag=true]', $select2Element).val(data.id)
                        $('<option value="' + data.id + '">' + data.name + '</option>').appendTo($select2Element)

                        var selection = $select2Element.val();
                        var index = selection.indexOf(data.name);

                        if (index !== -1) {
                            selection[index] = data.id.toString();
                        }

                        $select2Element.val(selection).trigger('change');

                        // alert($('option[value=' + data.id + '][data-select2-tag=true]', $select2Element).val())
                        // ev.params.data.id = data.id
                    },
                    error: function(jqXhr) {
                        // if( jqXhr.status === 422 )
                        // showErrors(form, jqXhr.responseJSON);
                    }
                })
            }
        })
    }

    $(document).ajaxStart(function(handler) {
        // ajaxActivatorElement = $(handler.target.activeElement)
        if ( ajaxActivatorElement == null ) ajaxActivatorElement = $(handler.target.activeElement)
        ajaxActivatorElementHtml = ajaxActivatorElement.html()

        if ( ! ajaxActivatorElement.is('input[type=text]'))
            ajaxActivatorElement.attr('disabled', 'disabled')

        ajaxActivatorElement.html(spinnerHtml)
    })

    $(document).ajaxStop(function() {
        ajaxActivatorElement.removeAttr('disabled')
        ajaxActivatorElement.html(ajaxActivatorElementHtml)
        ajaxActivatorElement.focus()

        ajaxActivatorElement = null
        ajaxActivatorElementHtml = null
    })

    $(document).on('click', '.open-image-browser', function(e) {
        e.preventDefault();

        openImageBrowser($(this));
    })

})

(function() {

    $(document).on('click', 'a#toggle-meta-panel', function(e) {
        e.preventDefault();

        $('#main').toggleClass('shrink');
    })


    /* Background image preview handling */
    $(document).on('change', '.media_url', function(e) {
        var $this = $(this)
        var $img = $($this.data('update-element'))
        var $previewArea = $img.parents('.image-preview')

        $img.attr('src', $this.val())

        if ( $this.val() == "" ) {
            $previewArea.addClass('empty')
            $img.hide()
        }
        else {
            $previewArea.removeClass('empty')
            $img.show()
        }
    })
    $(document).on('click', 'a.remove-preview-image', function(e) {
        e.preventDefault();

        $($(this).data('update-element')).val('').trigger('change')
    })
    /* END of background image preview handling */


    function prepareActionMenu(el) {
        var resource = $('ul.dropdown-menu', el).data('resource')
        var $wrapper = $('.wrapper', el.parent().closest('.section'))
        var $activeElement = $('ul li.active', $wrapper)
        var resourceID = $activeElement.data('id')
        var profileID = $activeElement.data('profile-id')

        $('ul.dropdown-menu li', el).show();

        if ( resourceID === undefined ) {
            $('ul.dropdown-menu li:not(:first-child)', el).hide()
        } else {
            $('ul.dropdown-menu li', el).each( function(i) {
                var element = $(this)

                if ( ! element.hasClass('divider') && i ) {
                    var $a = $('a', element)

                    if ( resourceID === undefined ) {
                        element.hide()
                        element.addClass('disabled')

                        $a.removeData('toggle target')
                        $a.attr('href', '#')
                    } else {
                        var act = $a.data('action')
                        var target = null

                        if ( element.hasClass('move') ) {
                            if ( act == 'move-left' && $activeElement.is(':first-child') ||
                                 act == 'move-right' && $activeElement.is(':last-child') ) {
                                element.hide()
                            }
                            target = '#move-' + resource
                        } else {
                            target = '#' + act + '-' + resource
                        }
                        element.removeClass('disabled')

                        $a.attr('data-toggle', 'modal')
                        $a.attr('data-target', target)
                        $a.attr('href', '/admin/profiles/' + profileID + '/' + resource + '/' + resourceID + '/' + act)
                    }
                }
            })
        }
    }

    $('.section .toolbar .dropdown').on('show.bs.dropdown', function () {
        prepareActionMenu($(this))
    })

    window['addSlide'] = function(form, data) {
        var $slidesArea = $('#section-slider .profile-slider ul'),
            $slideNav = $('#section-slider .unslider-nav'),
            count = $('li', $slidesArea).length;

        $slidesArea.append(data)

        if ( count == 0 ) {
            $('#section-slider').removeClass('empty')

            slider = $('.profile-slider').unslider({
                activeClass: 'active',
            })
        } else {
            $slideNav.remove()
            slider.unslider('calculateSlides')
            slider.unslider('initNav')
        }
    }
    window['updateSlide'] = function(form, data) {
        var $slidesArea = $('#section-slider .profile-slider ul'),
            slideID = $('input[name=slide_id]', form).val(),
            $activeSlide = $('li.active', $slidesArea),
            $newSlide = $(data);

        // $activeSlide.html($('li', $newSlide).html()).addClass('active');
        $activeSlide.replaceWith(data)
        slider.unslider('calculateSlides')
        $slidesArea.find("li[data-id=" + slideID + "]").addClass('active')
    }
    window['removeSlide'] = function(form, data) {
        var $slidesArea = $('#section-slider .profile-slider ul'),
            slideID = data.id;

        $slidesArea.find('li[data-id=' + slideID + ']').remove()
        $('#section-slider .unslider-nav').remove()
        if ( $('li', $slidesArea).length ) {
            slider.unslider('calculateSlides')
            slider.unslider('initNav')
            slider.unslider('animate:first')
        } else {
            // $('#section-slider').addClass('empty');
        }
        // $('li:first-child', $slidesArea).addClass('active');
    }

    window['updateContents'] = function(form, data) {
        var $titles = $('#section-contents .wrapper ul.nav-tabs')
        var $contents = $('#section-contents .wrapper .tab-content')
        var count = $('li', $titles).length

        var action = $('#crud_action', form).val()

        if ( 'create' == action ) {
            var tmpContent = $('<div>')

            tmpContent.html(data)
            $titles.append(tmpContent.find("li.tab"))
            $contents.append(tmpContent.find("div.tab-pane"))

            if ( count == 0 ) {
                $('li:first-child', $titles).addClass('active')
                $('.tab-pane:first-child', $contents).addClass('active')
            }

            $('#section-contents').removeClass('empty')
        }

        if ( 'update' == action ) {
            var tmpContent = $('<div>')
            var contentID = $('input[name=content_id]', form).val()
            var $title = $titles.find("li[data-id=" + contentID + ']')
            var $content = $contents.find(".tab-pane[data-id=" + contentID + ']')
            var activeContent  = $title.hasClass('active')

            tmpContent.html(data)
            var $newTitle = tmpContent.find("li.tab")
            var $newContent = tmpContent.find("div.tab-pane")

            if ( activeContent ) {
                $newTitle.addClass('active')
                $newContent.addClass('active')
            }

            $title.replaceWith($newTitle)
            $content.replaceWith($newContent)
        }

        if ( 'delete' == action ) {
            $titles.find("li[data-id=" + data.id + ']').remove()
            $contents.find(".tab-pane[data-id=" + data.id + ']').remove()
            count--

            if ( count ) {
                $('li:first-child', $titles).addClass('active')
                $('.tab-pane:first-child', $contents).addClass('active')
            } else {
                $titles.parents('.section').addClass('empty')
            }
        }

        if ( 'move' == action ) {
            console.log('moving...')

            var $title = $titles.find("li[data-id=" + data.id + ']')

            if ( 'right' == data.direction ) {
                $title.next().after($title);
            }
            if ( 'left' == data.direction ) {
                $title.prev().before($title)
            }
        }
    }

    window['updateContactInformation'] = function(form, data) {
        $('#section-contacts .wrapper').html(data);
    }

    window['updateCategoryAndTags'] = function(form, data) {

    }

    window['addProduct'] = function(form, data) {
        $('#products-listing').append(data);
    }

    window['updateProduct'] = function(form, data) {
        var productID = $('input[name=product_id]', form).val();

        $('#products-listing .product[data-id=' + productID +']').replaceWith(data)
    }

    window['deleteProduct'] = function(form, data) {
        $('#products-listing .product[data-id=' + data.id +']').remove()
    }

})();
var ajaxActivatorElement = null
var ajaxActivatorElementHtml = null
var spinnerHtml = '<i class="fa fa-circle-o-notch fa-spin fa-fw margin-bottom"></i>'
var arrayModals = []

$(document).on('click', 'a[data-toggle=modal]', function(e) {
    e.stopPropagation()
    
    var
        $this = $(this),
        url = $this.attr('href');

    ajaxActivatorElement = $this

    $('#modals .holder').load(url, function( response, status, xhr ) {
        if ( status == 'success' ) {
            addModal( $($this.data('target'), $('#modals')) )

            if ( $this.data('remote-on-success') )
                $('form', getLastModal()).attr('data-remote-on-success', $this.data('remote-on-success'));

            $('#modals').append(getLastModal());
            getLastModal().modal();
        } else {
            if ( xhr.status == 401 ) window.location = '/login';
        }
    });

})

$(document).on('click', 'a.assigndeveloper', function(e) {
    e.preventDefault();

    if ( confirm($(this).data('confirm')) ) {
        var $this = $(this)
        var $el = $this.parents('div.profile')

        ajaxActivatorElement = $this

        $.ajax({
            type     : 'GET',
            url      : $this.attr('href'),
            dataType : 'html',
            processData: false,
            success  : function( data, textStatus, jqXhr ) {
                if ( data != '' ) {
                    $el.replaceWith(data)
                }
            },
            error: function(jqXhr) {
                if( jqXhr.status === 422 )
                    showErrors(form, jqXhr.responseJSON);
            }
        })
    }

})

$(document).on('hidden.bs.modal', '#modals .modal', function (e) {
    if ( $('#textarea_wysiwyg').length )
        if ( CKEDITOR.instances.textarea_wysiwyg )
            CKEDITOR.instances.textarea_wysiwyg.destroy();

    $(this).remove()
    removeLastModal()
})

var submitLaravelRequest = function(e) {
    e.preventDefault();

    var form       = $(this);
    var dataType   = form.data('remote-data-type') || 'json';
    var method     = form.find('input[name="_method"]').val() || 'POST';
    var formData   = form.serialize();

    ajaxActivatorElement = $('button[type=submit]', form)

    $.ajax({
        type     : method,
        url      : form.prop('action'),
        data     : formData,
        dataType : dataType,
        // contentType: uploadForm ? false : "application/x-www-form-urlencoded; charset=UTF-8",
        processData: false,
        success  : function( data, textStatus, jqXhr ) {
            clearErrors(form);

            if ( getLastModal() != null ) getLastModal().modal('hide')

            if ( form.data('remote-on-success') ){
                window[form.data('remote-on-success')](form, data);
            }
        },
        error: function(jqXhr) {
            if( jqXhr.status === 422 ) {
                var errors = null;

                if ( dataType == 'json')
                    errors = jqXhr.responseJSON

                if ( dataType == 'html' )
                    errors = JSON.parse(jqXhr.responseText)

                showErrors(form, errors);
            }
        }
    });
}

window['flashmessage'] = function(form, data) {
    var message = form.data('remote-success-message');

    if ( message )
        $('#global-flash').html(message).fadeIn(300).delay(2500).fadeOut(500)
}

window['refresh'] = function (form, data) {
    window.location.reload()
}

window['update'] = function (form, data) {
    $('.main-container').append(data);
}


function showErrors($form, errors) {
    clearErrors($form)

    $.each( errors , function( key, value ) {
        var
            normalizeKey = key.replace( '.', '-'),
            $el          = $('#' + normalizeKey, $form),
            $parent      = $el.parent().closest('.form-group'),
            $errorPlace  = $('.error', $parent),
            errorHtml    = '';

        $parent.addClass('has-error');
        errorHtml = '<p class="text-danger">' + value[0] + '</p>';
        $errorPlace.append(errorHtml);
    });
}
function clearErrors($form) {
    $('p.text-danger', $form).remove();
    $('.has-error', $form).removeClass('has-error');
}

function addModal(modal) { arrayModals.push(modal) }
function removeLastModal() { arrayModals.pop() }
function getLastModal() { return arrayModals[arrayModals.length - 1] }
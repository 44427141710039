(function() {

    $(document).on('click', 'a#add-new-contact-informaion', function(e) {
        e.preventDefault();

        var contactsForm = $('form#manage-contacts-form'),
            activeTable = $('.tab-pane.active table', contactsForm),
            rowToCopy = $('tr:first-child', activeTable),
            newRow = $(document.createElement('tr')).html(rowToCopy.html());

        // $('input', newRow).val('sdf');

        newRow.appendTo($('tbody', activeTable))
    })

    $(document).on('click', '#manage-contacts-form a.remove-row', function (e) {
        e.preventDefault()

        var contactsForm = $('form#manage-contacts-form'),
            activeTable = $('.tab-pane.active table', contactsForm);

        if ( $('tbody tr', activeTable).length > 1 ) {
            // remove the row
            $(this).parents('tr').remove();
        }
        else {
            $('input[type=text]', $(this).parents('tr')).val('')
        }

    })


})();